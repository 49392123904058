/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}
body {
  min-height: 100%;
}
